<!-- 查询 -->
<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>首页</el-breadcrumb-item>
      <el-breadcrumb-item>订单管理</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card>
      <el-row :gutter="20">
        <el-col :span="3">
          <el-button type="primary" @click="exportOrderExcel"
          >导出Excel<i class="el-icon-upload el-icon--right"></i>
          </el-button>
        </el-col>
        <el-col :span="4">
          <el-button type="primary" @click="viewTodayMoney"
          >查看今日收入报表
          </el-button>
        </el-col>
        <el-col :span="4">
          <el-button type="primary" @click="viewTodayMoneyQudao"
          >查看今日渠道收入报表
          </el-button>
        </el-col>
        <el-col :span="4">
          <el-button type="primary" @click="goReport"
          >报表查询
          </el-button>
        </el-col>
        <el-col :span="4">
          <div style="margin-top: 10px">总计共售出{{ goodsTotal }}张票</div>
        </el-col>
      </el-row>
    </el-card>
    <!-- 卡片视图 -->

    <el-card>
      <!-- 搜索框 -->
      <el-row :gutter="12">
        <el-col :span="4">
          <el-input
            placeholder="请输入手机号"
            clearable
            v-model="queryInfo.phone"
            class="input-with-select"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="getOrderList"
            ></el-button>
          </el-input>
        </el-col>
        <el-col :span="4">
          <el-input
              placeholder="请输入订单号"
              clearable
              v-model="queryInfo.orderNo"
              class="input-with-select"
          >
            <el-button
                slot="append"
                icon="el-icon-search"
                @click="getOrderList"
            ></el-button>
          </el-input>
        </el-col>
        <el-col :span="4">
          <el-date-picker
              type="date"
              placeholder="选择起始日期"
              v-model="queryInfo.startTime"
              @change="getOrderList"
          ></el-date-picker>
        </el-col>
        <el-col :span="4">
          <el-date-picker
              type="date"
              placeholder="选择截止日期"
              v-model="queryInfo.endTime"
              @change="getOrderList"
          ></el-date-picker>
        </el-col>
        <!-- <el-col :span="3">
          <el-select v-model="queryInfo.orderType" clearable placeholder="支付方式" @change="getOrderList()">
            <el-option v-for="item in orderTypeOptions" :key="item.Value" :label="item.Key" :value="item.Value">
            </el-option>
          </el-select>
        </el-col> -->
        <el-col :span="3" v-if="cooperType!='6'">
          <el-select
              v-model="queryInfo.qudaoType"
              clearable
              placeholder="渠道"
              @change="getOrderList"
          >
            <el-option
                v-for="(item, index) in qudaoTypeList"
                :key="index"
                :label="item.Key"
                :value="item.name"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="3">
          <el-select
              v-model="queryInfo.status"
              clearable
              placeholder="订单状态"
              @change="getOrderList"
          >
            <el-option
                v-for="item in statusOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="2" v-if="isAdmin == '1'">
          <el-select
              v-model="queryInfo.userId"
              clearable
              placeholder="售票员"
              @change="getOrderList"
          >
            <el-option
                v-for="item in sellerOptions"
                :key="item.userID"
                :label="item.管理员姓名"
                :value="item.userID"
            >
            </el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row :gutter="16">
        <el-col :span="4">
          <el-input
            placeholder="请输入区域"
            clearable
            v-model="queryInfo.area_info.area_name"
            class="input-with-select"
          >
          </el-input>
        </el-col>
        <el-col :span="4">
          <el-input
            placeholder="请输入行号"
            clearable
            v-model="queryInfo.area_info.row"
            class="input-with-select"
          >
          </el-input>
        </el-col>
        <el-col :span="4">
          <el-input
            placeholder="请输入列号"
            clearable
            v-model="queryInfo.area_info.col"
            class="input-with-select"
          >
          </el-input>

        </el-col>
        <el-col :span="4">
            <el-button
              type="primary"
              icon="el-icon-search"
              @click="getOrderList"
            ></el-button>
            <el-button type="primary" icon="el-icon-delete"  @click="cleanArea()"></el-button>
        </el-col>
      </el-row>
      <!-- 订单列表 -->
      <el-table :data="orderList" border stripe v-loading="table_loading">
        <el-table-column type="expand">
          <template slot-scope="props">
            <el-form label-position="left" inline class="demo-table-expand">
              <el-form-item label="订单号: ">
                <span>{{ props.row.订单号 || "无" }}</span>
              </el-form-item>
              <el-form-item label="票种名称: ">
                <span>{{ props.row.区 || "无" }}</span>
              </el-form-item>
              <el-form-item label="购票时间: ">
                <span>{{ props.row.购买时间 || "无" }}</span>
              </el-form-item>
              <el-form-item label="场次: ">
                <span>{{ props.row.省 || "" }}</span>
              </el-form-item>
              <el-form-item label="价格: ">
                <span>{{ props.row.价格 || "0" }}</span>
              </el-form-item>
              <el-form-item label="预计使用时间: ">
                <span>{{ props.row.预计使用时间 || "无" }}</span>
              </el-form-item>
              <el-form-item label="验证时间: ">
                <span>{{ props.row.验证时间 || "无" }}</span>
              </el-form-item>
              <el-form-item label="验证人: ">
                <span>{{ props.row.验票员?.管理员姓名 || "无" }}</span>
              </el-form-item>
              <el-form-item label="姓名: ">
                <span>{{ props.row.收货人 || "匿名" }}</span>
              </el-form-item>
              <el-form-item label="手机号: ">
                <span>{{ props.row.手机号 || "无" }}</span>
              </el-form-item>
              <el-form-item label="使用状态: ">
                <span>{{ props.row.使用状态 || "无" }}</span>
              </el-form-item>
              <el-form-item
                  label="关联旅社: "
                  v-if="props.row.是否团队票 == '1'"
              >
                <span>{{
                    props.row.团队?.机构名称 || props.row.订单来源
                  }}</span>
              </el-form-item>
              <el-form-item
                  label="订单来源: "
                  v-if="props.row.是否团队票 != '1'"
              >
                <span>{{ props.row.订单来源 || "无" }}</span>
              </el-form-item>
              <el-form-item
                  label="团队名称: "
                  v-if="props.row.是否团队票 == '1'"
              >
                <span>{{ props.row.来源订单号 || "无" }}</span>
              </el-form-item>
              <el-form-item
                  label="订单凭证: "
                  v-if="props.row.是否团队票 != '1'"
              >
                <span>{{ props.row.来源订单号 || "无" }}</span>
              </el-form-item>
              <el-form-item label="售票员: ">
                <span>{{ props.row.售票员?.管理员姓名 || "无" }}</span>
              </el-form-item>
              <el-form-item label="核销渠道: ">
                <span>{{ props.row.核销渠道 || "无" }}</span>
              </el-form-item>
              <el-form-item label="商家退款员: ">
                <span>{{ props.row.退款员?.管理员姓名 || "无" }}</span>
              </el-form-item>
              <el-form-item label="出票时间: ">
                <span>{{ props.row.取票时间|| "未出票" }}</span>
              </el-form-item>
              <el-form-item label="商家出票员: ">
                <span>{{ props.row.AuditName || "无" }}</span>
              </el-form-item>
              <el-form-item label="备注: ">
                <span>{{ props.row.FailReason || "无" }}</span>
              </el-form-item>
              <el-form-item label="订单明细: ">
                <el-link
                    type="primary"
                    @click="orderDetail(props.row.ID)"
                    underline
                >查看<i class="el-icon-view el-icon--right"></i
                ></el-link>
              </el-form-item>
            </el-form>
          </template>
        </el-table-column>
        <el-table-column label="订单号" prop="订单号" width="120"></el-table-column>
        <el-table-column label="票种名称" prop="区"></el-table-column>
        <el-table-column label="区域" prop="座位区域" width="50"></el-table-column>
        <el-table-column
            label="购买时间"
            width="130"
            prop="购买时间"
        ></el-table-column>
        <el-table-column
            label="场次"
            width="150"
            prop="省"
        >
          <template slot-scope="scope">
            {{ (scope.row["省"]||'').replace(/-/g,'/').slice(0, 16) }}
          </template>
        </el-table-column>
        <el-table-column label="用户信息" width="120">
          <template slot-scope="scope">
            <div>{{ scope.row["手机号"]||'' }}</div>
            {{ scope.row["收货人"]||'匿名' }}
          </template>
        </el-table-column>
        <el-table-column label="数量" prop="数量" width="80">
          <template slot-scope="scope">
            <span v-if="scope.row['使用状态']=='已退款'">0</span><span v-else>{{ scope.row["数量"]-scope.row['cancel_num']||0}}单|{{scope.row['座位数']}}张</span>
          </template>
        </el-table-column>
        <el-table-column label="使用状态" prop="使用状态"></el-table-column>
        <el-table-column label="价格" prop="价格">
          <template slot-scope="scope">
            <span v-if="scope.row['使用状态']=='已退款'">0</span><span v-else>{{ scope.row["价格"] }}</span>
          </template>
        </el-table-column>
        <el-table-column label="渠道" prop="位置">
          <template slot-scope="scope">
            <span v-if="scope.row['订单来源']=='小程序'">微信小程序</span><span v-if="scope.row['订单来源']=='票付通'">九江万达</span><span v-else>{{ scope.row["位置"] }}</span>
          </template>
        </el-table-column>
        <el-table-column label="支付方式" prop="订单来源">
          <template slot-scope="scope">
            {{ scope.row["订单来源"] | getPayType(scope.row["支付方式"]) }}
          </template>
        </el-table-column>
        <el-table-column label="出票信息" width="200">
          <template slot-scope="scope">
            <span v-if="scope.row['是否取票']==0">未出票</span>
            <span v-else>{{scope.row['取票时间']}},出票人:{{scope.row['AuditName']}}</span>
          </template>
        </el-table-column>
        <el-table-column
            label="售票员"
            prop="售票员.管理员姓名"
        ></el-table-column>
        <el-table-column
          label="备注"
          prop="FailReason"
        ></el-table-column>
        <el-table-column label="操作" width="180" v-if="cooperType!='6'">
          <template slot-scope="scope">
            <template v-if="workerId!=='26987631-8d74-4758-bf86-d80127b2cb99'">
              <el-button
                  @click="printTicket(scope.row)"
                  type="primary"
                  size="mini"
              >打票
              </el-button>
              <el-button type="warning" size="mini" @click="closeOrder(scope.row)"
              >作废
              </el-button>
              <el-button
                  type="success"
                  size="mini"
                  @click="writeOffOrder(scope.row)"
              >核验
              </el-button>
            </template>

            <el-button
              type="info"
              size="mini"
              @click="addNote(scope.row)"
            >备注
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页 -->
      <el-pagination
          @current-change="handleCurrentChange"
          :current-page="queryInfo.currentPage"
          :page-size="queryInfo.pageSize"
          layout="total, prev, pager, next, jumper"
          :total="total"
      >
      </el-pagination>
    </el-card>

    <!-- 订单明细 -->
    <el-dialog
        title="订单明细页"
        :visible.sync="showInfoDialogVisible"
        width="50%"
    >
      <template>
        <el-form label-position="left" inline class="demo-table-expand">
          <el-form-item label="订单号: ">
            <span>{{ orderDetailInfo.订单号 || "无" }}</span>
          </el-form-item>
          <el-form-item label="总金额: ">
            <span>{{ orderDetailInfo.价格 || "无" }}</span>
          </el-form-item>
        </el-form>

        <!-- 订单明细 -->
        <el-table :data="orderDetailInfo.订单明细" border stripe>
          <el-table-column type="index"></el-table-column>
          <el-table-column label="名称" prop="名称"></el-table-column>
          <el-table-column label="单价" prop="价格"></el-table-column>
          <el-table-column label="数量" prop="数量"></el-table-column>
          <el-table-column label="剩余数量" prop="剩余数量"></el-table-column>
        </el-table>

        <!-- 订单人员 -->
        <el-table
            :data="orderDetailInfo.订单人员"
            v-if="orderDetailInfo.订单人员 && orderDetailInfo.订单人员.length > 0"
            border
            stripe
        >
          <el-table-column type="index"></el-table-column>
          <el-table-column label="客户姓名" prop="姓名"></el-table-column>
          <el-table-column label="手机号" prop="手机号码"></el-table-column>
          <el-table-column label="身份证号" prop="身份证号码"></el-table-column>
        </el-table>

        <!-- 订单座位 -->
        <el-table
            :data="orderDetailInfo.订单座位"
            v-if="orderDetailInfo.订单座位 && orderDetailInfo.订单座位.length > 0"
            border
            stripe
        >
          <el-table-column type="index"></el-table-column>
          <el-table-column label="座位名称" prop="名称"></el-table-column>
          <el-table-column label="区域" prop="区域名"></el-table-column>
          <el-table-column label="演出厅" prop="影厅名称"></el-table-column>
          <el-table-column label="是否检票">
            <template slot-scope="scope">
              <span v-if="scope.row['EntityStatus']==4">已退票</span>
              <span v-else-if="scope.row['isChecked']==1">未检票</span>
              <span v-else>已检票<br>{{scope.row['ChangedTime']}}</span>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </el-dialog>

    <!-- 今日报表 -->
    <el-dialog
        title="《景德镇记忆》收入报表"
        :visible.sync="showTodayMoneyVisible"
        width="50%"
    >
      <h2 style="margin-top: -20px">
        日期{{ dateSelect | filtersFormatDate }}
      </h2>
      <div style="padding: 10px">
        选择查看日期：
        <el-date-picker
            v-model="dateSelect"
            align="right"
            :clearable="false"
            type="date"
            placeholder="选择日期"
            :picker-options="pickerOptions"
            @change="selsectDate"
        >
        </el-date-picker>
      </div>

      <div class="money-table" v-if="todayMonery.length !== 0">
        <div class="money-table-row">
          <div style="border-left: none">今日总收入</div>
          <div>{{ totalIncome }}</div>
          <div>出票总张数：</div>
          <div>{{ dayilNumber }}</div>
        </div>
        <div class="money-table-row">
          <div class="table-row-two">分项收入</div>
          <div class="row-two-item" style="border-bottom: none">
            <div
                class="item"
                v-for="(item, index) in formatMoneryKeys"
                :key="index"
            >
              <div>{{ item }}:</div>
              <div style="color: #f286c4">{{ formatMonery[item]}}</div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="todayMonery.length == 0">今日暂无收入统计</div>
      <el-table
          v-else
          :data="todayMonery"
          border
          :cell-style="{
          textAlign: 'center',
        }"
          :header-cell-style="{ textAlign: 'center' }"
      >
        <el-table-column prop="场次时间" label="日期场次">
          <template slot-scope="scope">
            <span>{{ (scope.row['场次时间']||'').replace(/-/g,'/') }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="支付方式" label="分项收入及类型">
          <template slot-scope="scope">
            <span>{{ scope.row['订单来源'] | getPayType(scope.row['支付方式']) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="money" label="金额"></el-table-column>
      </el-table>
    </el-dialog>

    <!-- 渠道报表 -->
    <el-dialog
        title="《景德镇记忆--渠道报表》"
        :visible.sync="showTodayQudaoVisible"
        width="50%"
    >
      <div style="margin-top: -20px">
        <span>日期{{ dateQudaoSelect[0] | filtersFormatDate }}</span>
        至
        <span>{{ dateQudaoSelect[1] | filtersFormatDate }}</span>
      </div>
      <div style="padding: 10px">
        <div>
          选择查看日期：
          <el-date-picker
              v-model="dateQudaoSelect"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="selectDateQudao"
          >
          </el-date-picker>
        </div>

        <div v-if="cooperType!='6'">
          选择查看渠道类别：
          <el-select
              v-model="activeQudaoNameType"
              clearable
              placeholder="选择查看渠道类别"
              @change="selectQudaoType"
          >
            <el-option
                v-for="(item, index) in qudaoTypeList"
                :key="index"
                :label="item.name"
                :value="item.name"
            >
            </el-option>
          </el-select>
        </div>

        <div v-if="cooperType!='6'">
          选择查看渠道：&emsp;&emsp;
          <el-select
              v-model="activeQudaoName"
              clearable
              placeholder="选择查看渠道"
              @change="selectQudao"
          >
            <el-option
                v-for="(item, index) in qudaoDataList"
                :key="index"
                :label="item.name"
                :value="item.name"
            >
            </el-option>
          </el-select>
        </div>
      </div>

      <div v-if="qudaoTodayList.length == 0">暂无收入统计</div>
      <el-table
          v-else
          :data="qudaoTodayList"
          border
          :cell-style="{ textAlign: 'center' }"
          :header-cell-style="{ textAlign: 'center' }"
          show-summary
          :summary-method="getSummaries"
      >
        <el-table-column prop="渠道类型" label="渠道类型"></el-table-column>
        <!-- <el-table-column label="渠道名称">
          <template scope="scope">
            <div v-for="(item, index) in scope.row.渠道名称" :key="index">
              {{item.name}}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="票种" label="票种"> </el-table-column>
        <el-table-column prop="数量" label="数量"> </el-table-column> -->
        <el-table-column prop="场次时间" label="场次时间"></el-table-column>
        <el-table-column prop="number" label="总销售额"></el-table-column>
        <el-table-column prop="money" label="总金额"></el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import DateFormat from "@/utils/DateFormat.js";
import {
  getReportByWay,
  getReportByQuDao,
  qudaoTypeList,
  qudaoList,
  qudaoByid,
} from "@/network/channel.js";

export default {
  name: "order",
  data() {
    return {
      queryInfo: {
        searchStr: "",
        // 当前的页数
        currentPage: 1,
        pageSize: 10,
        status: "",
        startTime: "",
        endTime: "",
        userId: "",
        orderType: "",
        qudaoType: "",
        area_info:{area_name:'',row:"",col:""},
        phone:''
      },
      table_loading:false,
      total: 0,
      showInfoDialogVisible: false,
      showTodayMoneyVisible: false, //今日报表框
      orderList: [],
      statusOptions: [
        {
          //   value: '0',
          //   label: '未支付'
          // }, {
          value: "1",
          label: "已支付",
        },
        {
          value: "2",
          label: "已完成",
        },
        {
          value: "3",
          label: "已退款",
          // }, {
          //   value: '4',
          //   label: '已关闭'
        },
      ],
      orderDetailInfo: [],
      orderDetailPage: 1,
      orderTypeOptions: [],
      sellerOptions: [],
      goodsTotal: 0,
      isAdmin: window.sessionStorage.getItem("isAdmin"),
      companyId: window.sessionStorage.getItem("companyId"),
      workerId:window.sessionStorage.getItem('userId'),
      // 当天收入报表
      todayMonery: [],
      // 格式化后的当天报表数据
      formatMonery: {},
      formatMoneryKeys: [],
      // 总收入
      totalIncome: 0,
      // 选择报表日期
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now() + 24 * 60 * 60 * 1000;
        },
      },

      // 选择报表日期-时间戳
      dateSelect: "",
      // 渠道mode显示
      showTodayQudaoVisible: false,
      // 渠道数据
      qudaoTypeList: [],
      // 渠道日期内的数据
      qudaoTodayList: [],
      // 选择渠道日期
      dateQudaoSelect: [
        `${new Date(new Date().getTime() - 24 * 60 * 60 * 1000)}`,
        `${new Date()}`,
      ],
      // 选择渠道日期-时间戳
      dateQudaoSelectTime: [],
      // 选择渠道名
      activeQudaoNameType: "",
      // 获取渠道数据传的参数
      qudaoTypeParam: {},
      // 当前渠道销售数据
      acticveQudaoData: [],
      // 格式化订单数据
      selectedSeatList: [],
      // 渠道类型下的渠道
      qudaoDataList: [],
      // 选中的渠道
      activeQudaoName: "",
      cooperType: 0,
      dayilNumber:0
    };
  },
  created() {
    this.cooperType = window.sessionStorage.getItem('cooperType') || 0
    if (this.cooperType=='6'){
      this.goReport()
      return
    }
    this.getOrderList();
    this.getOrderTypeOptions();
    this.getSellerList();
    this.getQudaoTypeList();
  },
  computed: {},
  methods: {
    // // 合并单元格
    // objectSpanMethod({ row, column, rowIndex, columnIndex }) {
    //   console.log('row, column, rowIndex, columnIndex: ', row, column, rowIndex, columnIndex);
    //   if (columnIndex === 0) {
    //     if (rowIndex % 2 === 0) {
    //       return {
    //         rowspan: 2,
    //         colspan: 2,
    //       };
    //     } else {
    //       return {
    //         rowspan: 0,
    //         colspan: 0,
    //       };
    //     }
    //   }
    // },
    cleanArea(){
      this.queryInfo.area_info={area_name: '',row:'',col:''}
      this.getOrderList()
    },
    goReport() {
      this.$router.push({
        path: "/message/reportjy",
      });
    },
    // 添加总计
    getSummaries(param) {
      const {columns, data} = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "总价";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        if (!values.every((value) => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          if (index === 2) {
            sums[index] += " 次";
          } else {
            sums[index] += " 元";
          }
        } else {
          sums[index] = "N/A";
        }
      });

      return sums;
    },

    // 选择日期
    selsectDate(e) {
      console.log("选了时间: ", e);
      let date = new Date(e);
      this.dateSelect = date;
      console.log("selsectDate: ", this.dateSelect);
      this.getReportByWayData();
    },
    // 渠道选择日期
    selectDateQudao() {
      this.getReportByQuDaoData();
    },

    // 选择渠道类型
    selectQudaoType(tag) {
      console.log("tag: ", tag);
      let that = this
      this.qudaoDataList = [];
      this.activeQudaoName = "";
      this.qudaoTypeParam.typeName = tag;
      this.getReportByQuDaoData();
      console.log(' this.qudaoTypeList: ', this.qudaoTypeList);

      for (let i = 0; i < this.qudaoTypeList.length; i++) {
        if (this.qudaoTypeList[i].name == tag) {
          that.getQudaoList(this.qudaoTypeList[i].ID);
        }
      }

    },

    // 获取渠道
    async getQudaoList(id) {
      this.qudaoDataList = []
      let res = await qudaoByid({id});
      console.log("res: ", res);
      if (res.data && res.data.length > 0) {
        this.qudaoDataList = res.data;
      }
    },

    // 选择渠道
    selectQudao(tag) {
      console.log("tag: ", tag);
      this.qudaoTypeParam.qudao = tag;
      this.getReportByQuDaoData();
    },

    // start,end,typeName
    // 获取渠道销售额
    async getReportByQuDaoData() {
      let that = this;
      this.dateQudaoSelect.map((item, index) => {
        that.dateQudaoSelect[index] = new Date(item).getTime();
      });
      this.qudaoTypeParam.start =  new DateFormat(this.dateQudaoSelect[0]).toString("yyyy-0m-0d");
      this.qudaoTypeParam.end = new DateFormat(this.dateQudaoSelect[1]).toString("yyyy-0m-0d");
      this.qudaoTodayList = [];

      let res = await getReportByQuDao(this.qudaoTypeParam);
      console.log("getReportByQuDaores: ", res);
      if (res.data && res.data.length > 0) {
        this.qudaoTodayList = res.data;
        console.log("this.qudaoTodayList: ", this.qudaoTodayList);
        // this.fromatQudaoTodayData();
      }
    },
    // 格式化渠道报表数据
    async fromatQudaoTodayData() {
      // 添加id
      for (let i = 0; i < this.qudaoTypeList.length; i++) {
        for (let j = 0; j < this.qudaoTodayList.length; j++) {
          if (
              this.qudaoTypeList[i].name == this.qudaoTodayList[j]["渠道类型"]
          ) {
            this.qudaoTodayList[j].ID = this.qudaoTypeList[i].ID;
          }
        }
      }
      this.qudaoTodayList.forEach(async (item, index) => {
        await this.getQudaoList(item.ID)
        item['渠道名称'] = this.qudaoDataList
      });
      console.log("this.qudaoTodayList: ", this.qudaoTodayList);
    },

    // 点击渠道报表
    viewTodayMoneyQudao() {
      delete this.qudaoTypeParam.typeName;
      delete this.qudaoTypeParam.qudao;
      this.activeQudaoNameType = "";
      this.activeQudaoName = "";
      console.log("this.qudaoTypeParam: ", this.qudaoTypeParam);
      this.showTodayQudaoVisible = true;
      this.getReportByQuDaoData();
    },

    // 获取渠道
    async getQudaoTypeList() {
      let res = await qudaoTypeList();
      console.log("res: ", res);
      if (res.data && res.data.length > 0) {
        this.qudaoTypeList = [{name:"九江万达",ID:'pft'},{name:'畅游小程序',ID:'changyou'}].concat(res.data);
      }
    },

    // 获取当天收入报表
    async viewTodayMoney() {
      this.showTodayMoneyVisible = true;
      let time = Date.now();
      this.dateSelect = new Date(new Date(time).setHours(0, 0, 0, 0));
      this.getReportByWayData();
    },

    // 获取报表数据
    async getReportByWayData() {
      let res = await getReportByWay({date: new DateFormat(this.dateSelect).toString("yyyy-0m-0d")});
      // let res = await getReportByWay({ date: 1713283200000 });
      if (res.data && res.data.length > 0) {
        console.log("res: ", res);
        this.todayMonery = res.data;
        this.dayilNumber=0
        for(let item of res.data){
          this.dayilNumber+=Number(item.number)
        }
        this.formatOrderList(this.todayMonery);
        this.formatMoneryKeys = Object.keys(this.formatMonery);
        this.getTotalIncome();
        console.log("this.totalIncome:", this.totalIncome);
      } else {
        this.todayMonery = [];
      }
      console.log(" this.todayMonery: ", this.todayMonery);
    },

    // 格式化报表数据
    formatOrderList(data) {
      let that = this;
      that.formatMonery = {};
      console.log("data: ", data);
      data.map((item, index) => {
        if (that.formatMonery[item.支付方式]) {
          that.formatMonery[item.支付方式] = (
              Number(that.formatMonery[item.支付方式]) + Number(item.money)
          ).toFixed(2);
        } else {
          that.formatMonery[item.支付方式] = item.money.toFixed(2);
        }
      });
    },

    getTotalIncome() {
      this.totalIncome = 0;
      for (const key in this.formatMonery) {
        this.totalIncome += Number(this.formatMonery[key]);
      }
      this.totalIncome.toFixed(2);
    },

    exportOrderExcel() {
      let that = this;
      const loading = that.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      const name = `订单列表`;
      let queryInfo = {};
      if (this.queryInfo.startTime) {
        queryInfo.startTime = new DateFormat(
            new Date(this.queryInfo.startTime)
        ).toString("yyyy-0m-0d");
      }

      if (this.queryInfo.endTime) {
        queryInfo.endTime = new DateFormat(
            new Date(this.queryInfo.endTime)
        ).toString("yyyy-0m-0d");
      }
      if (this.queryInfo.orderType) {
        queryInfo.orderType = this.queryInfo.orderType;
      }
      if (this.queryInfo.qudaoType) {
        queryInfo.qudaoType = this.queryInfo.qudaoType;
      }
      if (this.queryInfo.area_info) {
        queryInfo.area_info = this.queryInfo.area_info;
      }
      if (this.queryInfo.phone) {
        queryInfo.phone = this.queryInfo.phone;
      }
      if (this.queryInfo.searchStr) {
        queryInfo.searchStr = this.queryInfo.searchStr;
      }
      if (this.queryInfo.userId) {
        queryInfo.userId = this.queryInfo.userId;
      }
      if (this.queryInfo.status) {
        queryInfo.status = this.queryInfo.status;
      }
      if (this.queryInfo.orderNo) {
        queryInfo.orderNo = this.queryInfo.orderNo;
      }
      if (this.isAdmin === "0") {
        console.log(
            "isAdmin",
            this.isAdmin,
            window.sessionStorage.getItem("userId")
        );
        queryInfo.userId = window.sessionStorage.getItem("userId");
      }

      console.log("queryInfo: ", queryInfo);
      console.log("that.queryInfo", that.queryInfo);

      that
          .$axios({
            url: `/manager/exportOrderExcel`,
            params: queryInfo,
            data: {
              data: that.queryInfo,
            },
            method: "GET",
            responseType: "blob",
          })
          .then(function (data) {
            if (!data) {
              return;
            }
            let url = window.URL.createObjectURL(new Blob([data]));
            let link = document.createElement("a");
            link.style.display = "none";
            link.href = url;
            link.setAttribute("download", name + ".xlsx");

            document.body.appendChild(link);
            link.click();

            loading.close();
            that.$message({
              type: "success",
              message: "正在导出报表...",
            });
          })
          .catch(function (err) {
            console.log(err);

            loading.close();
          });
    },
    getSellerList() {
      let that = this;
      that
          .$axios({
            url: `/manager/sellerList?timestemp=${new Date().getTime()}`,
            method: "GET",
          })
          .then((res) => {
            if (res.code == 200) {
              that.sellerOptions = res.data;
            } else {
              that.$message.warning(res.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
    },
    getOrderTypeOptions() {
      let id = "2e8ac8b3-e201-4c29-91d6-c09deb7785a9";
      let that = this;
      that
          .$axios({
            url: `/mall/enumDetail?id=${id}&timestemp=${new Date().getTime()}`,
            method: "GET",
          })
          .then((res) => {
            if (res.code == 200) {
              that.orderTypeOptions = res.data;
            } else {
              that.$message.warning(res.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
    },
    getGoodsTotal() {
      this.$axios({
        method: "get",
        url: "/manager/goodsTotal",
        params: this.queryInfo,
      })
          .then((res) => {
            // console.log(res.data);
            this.goodsTotal = res.data[0].total;
          })
          .catch((err) => err);
    },
    getOrderList(e,page=false) {
      if (!page){
        this.queryInfo.currentPage=1
      }
      this.table_loading=true
      function checkObjectValues(obj) {
        const values = Object.values(obj);
        if (values.every(value => value === "")) {
          return true; // 如果所有值都是空，则返回true
        } else if (values.every(value => value !== "")) {
          return true; // 如果所有值都不是空，则返回true
        } else {
          return false; // 否则返回false
        }
      }
      if (!checkObjectValues(this.queryInfo.area_info)) {
        this.$message.warning('座位查询时请将区域、行号、列号全部填写');
        return
      }
      if (this.queryInfo.startTime) {
        this.queryInfo.startTime = new DateFormat(
            new Date(this.queryInfo.startTime)
        ).toString("yyyy-0m-0d");
      }

      if (this.queryInfo.endTime) {
        this.queryInfo.endTime = new DateFormat(
            new Date(this.queryInfo.endTime)
        ).toString("yyyy-0m-0d");
      }

      if (this.isAdmin === "0") {
        console.log(
            "isAdmin",
            this.isAdmin,
            window.sessionStorage.getItem("userId")
        );
        this.queryInfo.userId = window.sessionStorage.getItem("userId");
      }

      this.$axios({
        method: "get",
        url: "/manager/orderList",
        params: this.queryInfo,
      })
          .then((res) => {
            this.table_loading=false
            if (res.code!==200){
              this.$message.warning(res.msg);
            }
            // console.log(res.data);
            function subHour(text) {
              let result = text.match(
                  /(?<year>\d+)年(?<month>\d+)月(?<day>\d+)日 (?<hour>\d+)时(?<min>\d+)分/
              );
              let _timestamp =
                  new Date(
                      Number(result.groups.year),
                      Number(result.groups.month) - 1,
                      Number(result.groups.day),
                      Number(result.groups.hour),
                      Number(result.groups.min),
                      0
                  ).getTime() -
                  60 * 1000 * 60 * 8;
              let time = new Date(_timestamp);
              return `${time.getFullYear()}年${
                  time.getMonth() + 1 >= 10
                      ? time.getMonth() + 1
                      : "0" + (time.getMonth() + 1)
              }月${
                  time.getDate() >= 10 ? time.getDate() : "0" + time.getDate()
              }日 ${
                  time.getHours() >= 10 ? time.getHours() : "0" + time.getHours()
              }时${
                  time.getMinutes() >= 10
                      ? time.getMinutes()
                      : "0" + time.getMinutes()
              }分`;
            }

            this.orderList = res.data.map((v) => {
              // v.购买时间=subHour(v.购买时间)
              // if(v.验证时间&&v.验证时间!=='无'){
              //   v.验证时间=subHour(v.验证时间)
              // }
              return v;
            });
            this.orderList = this.orderList.filter(v => {
              return v.使用状态 !== '未付款'
            })
            this.total = res.total;
            this.getGoodsTotal();

          })
          .catch((err) => {
            this.table_loading=false
            console.log(err)
          });

    },
    //页码改变函数
    handleCurrentChange(newPage) {
      this.queryInfo.currentPage = newPage;
      this.getOrderList(null,true);
    },
    orderDetail(id) {
      console.log(id);
      this.$axios({
        method: "get",
        url: "/manager/orderDetail",
        params: {
          orderId: id,
          pageNum: this.orderDetailPage,
        },
      })
          .then((res) => {
            // console.log(res.data);
            if (res.code == 200) {
              this.orderDetailInfo = res.data;
              this.showInfoDialogVisible = true;
            } else {
              this.$message.warning(res.msg);
            }
          })
          .catch((err) => err);
    },
    sellDetail() {
      this.$router.push({
        path: "/message/screens",
        query: {
          type: "9",
        },
      });
    },
    checkTicket(row) {
      console.log(row, this.orderDetailInfo);
    },
    addNote(row){
      let that=this
      this.$prompt('请输入备注信息', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(({ value }) => {
        const loading = that.$loading({
          lock: true,
          text: "添加中",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        that.$axios({
          method: "post",
          url: "/manager/addOrderNote",
          data: {
            ID: row.ID,
            note: value,
          },
        }).then((res) => {
          loading.close();
          if (res.code === 200) {
            that.$message.success("添加成功");
            that.getOrderList();
          } else {
            that.$message.warning(res.data);
          }
        });
      }).catch(() => {

      });

    },
    writeOffOrder(row) {
      let userid = window.sessionStorage.getItem("userId");
      let that = this;
      this.$confirm("请确认要核销该订单吗？", "核销废弃", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {
            const loading = that.$loading({
              lock: true,
              text: "核销中",
              spinner: "el-icon-loading",
              background: "rgba(0, 0, 0, 0.7)",
            });
            this.$axios({
              method: "post",
              url: "/order/writeOffOrder",
              data: {
                orderid: row.订单号,
                userid: userid,
              },
            }).then((res) => {
              loading.close();
              if (res.code === 200) {
                that.$message.success("核销成功");
                that.getOrderList();
              } else {
                that.$message.warning(res.data);
              }
            });
          })
          .catch(() => {
            console.log("用户点击取消");
          });
    },
    closeOrder(row) {
      let that = this;
      this.$confirm("请确认要废弃该订单吗？", "确认废弃", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {
            const loading = that.$loading({
              lock: true,
              text: "请求中",
              spinner: "el-icon-loading",
              background: "rgba(0, 0, 0, 0.7)",
            });
            this.$axios({
              method: "post",
              url: "/order/closeOrder",
              data: {
                orderid: row.订单号,
              },
            }).then((res) => {
              loading.close();
              if (res.code === 200) {
                that.$message.success("废弃成功");
                that.getOrderList();
              } else {
                that.$message.warning(res.data);
              }
            });
          })
          .catch(() => {
            console.log("用户点击取消");
          });
    },
    handleClick(row) {
    },
    printTicket(_order) {
      let order = JSON.parse(JSON.stringify(_order))
      console.log("order: ", order);
      let that = this;
      const loading = that.$loading({
        lock: true,
        text: "正在生成票据",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      try {
        order.购买时间 = order.购买时间.replace(/[年月日时分]/g, function (a) {
          console.log(a);
          if (["日", "分"].includes(a)) {
            return "";
          } else if (["年", "月"].includes(a)) {
            return "-";
          } else {
            return ":";
          }
        });
        let date = new DateFormat(new Date(order.购买时间)).toString(
            "yyyy-0m-0d"
        );
        console.log("orderDetailInfo:", this.orderDetailInfo);
        console.log("开始打印票据", date);
        let method = order.是否取票;
        let personType = order.是否团队票;
        console.log("开始请求详情");
        this.$axios({
          method: "get",
          url: "/manager/orderDetail",
          params: {
            orderId: order.ID,
            pageNum: that.orderDetailPage
          }
        })
            .then((res) => {
              console.log("res========: ", res);
              if (res.code == 200) {
                order["订单明细"] = res.data.订单明细;
                order["订单座位"] = res.data.订单座位;
                // order = [order];
                console.log("orderDetail====res: ", order);
                let path = "/message/ticket";
                let data = order.省;
                if (!data) {
                  data = new DateFormat(
                      new Date(order["预计使用时间"].replace(/年|月|日/g, '/'))
                  ).toString("yyyy/mm/dd") + ' 20:00';
                }
                data = this.timestampToDate(data);

                data = data.split(" ");
                console.log("data: ", data);
                order.qrUrl = that.genrateERCodeURL(order.订单号);
                console.log("开始生成二维码", order.qrUrl);
                order.订单座位.forEach((item, index) => {
                  if(item.EntityStatus==1){
                    that.selectedSeatList.push({
                      ID:item.ID,
                      区域名: item.区域名,
                      名称: item.名称,
                      价格信息: [
                        {
                          原价: "198.00",
                        },
                      ],
                    });
                  }

                });
                console.log("this.selectedSeatList: ", this.selectedSeatList);
                loading.close();
                that.$router.push({
                  path,
                  query: {
                    printInfo: this.selectedSeatList,
                    date: data,
                    time: "65",
                    orderNum: order.订单号,
                    ticketType: order.区,
                    userId:order.会员ID
                  },
                });
                // that.$axios({
                //   method: "post",
                //   url: "/order/getTicket",
                //   data: {
                //     memberid: order.会员ID||window.sessionStorage.getItem("userId")||'0001',
                //     orderid: order.订单号,
                //     auditID:window.sessionStorage.getItem("userId"),
                //     auditName:window.sessionStorage.getItem("userName")
                //   },
                // }).then(()=>{
                //
                // })

              } else {
                loading.close();
                that.$message.warning(res.msg);
              }
            })
            .catch((err) => {
              loading.close();
            });
      } catch (e) {
        loading.close();
      }
    },

    goPage(page) {
      console.log(page);
      this.$router.push(page);
    },
    genrateERCodeURL(orderId) {
      return `https://test-pyssp.iat.wang/newminipro?type=check&ID=${orderId}&t=${new Date().getTime()}`;
    },
    timestampToDate(dateStr) {
      let dateStr1 = dateStr.split(" ")
      var dateParts
      if (dateStr1[0].indexOf('/') !== -1) {
        dateParts = dateStr1[0].split("/");
      } else {
        dateParts = dateStr1[0].split("-");
      }
      var timeParts = dateStr1[1].split(":");
      var date = new Date(
          dateParts[0],
          dateParts[1] - 1,
          dateParts[2],
          timeParts[0],
          timeParts[1]
      );

      let year = date.getFullYear() + "年";
      let month = ("0" + (date.getMonth() + 1)).slice(-2) + "月";
      let day = ("0" + date.getDate()).slice(-2) + "日 ";
      let hour = date.getHours() + "点";
      let minute = ("0" + date.getMinutes()).slice(-2) + "分";

      return year + month + day + hour + minute;
    },
  },
  filters: {
    getPayType(typeID, way = '') {
      console.log(typeID, way)
      switch (typeID) {
        case "1":
          return "其他三方支付";
        case "2":
          return "现金支付";
        case "3":
          return "微信支付";
        case "4":
          return "支付宝支付";
        case "5":
          return "银联支付";
        case "6":
          return "OTA支付";
        case "7":
          return "VM";
        case "8":
          return "对公汇款";
        case "9":
          return "欠款";
        default:
          return way
          break;
      }
    },
    filtersFormatDate(timestamp) {
      const date = new Date(timestamp);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const formattedDate = `${year}-${month}-${day}`;
      return formattedDate;
    },
  },
};
</script>

<style scoped>
.demo-table-expand {
  font-size: 0;
}

.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}

.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}

.el-row:last-child {
  margin-bottom: 0;
}

.el-col {
  border-radius: 4px;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
  /* border: 1px solid gray; */
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  flex-shrink: 0;
}

.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}

.money-table {
  width: 100%;
  border: 1px solid #ebeef5;
  border-collapse: collapse;
}

.money-table-row {
  /* border: 1px solid red; */
  display: flex;
  flex-direction: row;
  align-items: center;
}

.money-table-row > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  border-left: 1px solid #ebeef5;
  border-bottom: 1px solid #ebeef5;
  min-height: 48px;
}

.table-row-two {
  height: 100%;
  border: none !important;
  flex: 1;
}

.row-two-item {
  flex: 1;
}

.row-two-item > div {
  min-height: 28px;
  width: 100%;
  border-bottom: 1px solid #ebeef5;
  display: flex;
  align-items: center;
  justify-content: center;
}

.row-two-item > :last-child {
  border-bottom: none !important;
}

/**改变边框颜色*/
/* .el-table {
  border-bottom: 1px solid #000;
  border-right: 1px solid #000;
  margin: 0 auto;
}
:deep.el-table th {
  border: 1px solid #000;
  border-right: none;
  border-bottom: none;
}
:deep.el-table td {
  border: 1px solid #000;
  border-right: none;
  border-bottom: none;
} */
/**改变表头标题颜色*/
/* :deep.el-table thead {
    color: #000;
} */
</style>
